<template>
<section class="latest" v-if="blogposts">
    <div class="container">
        <div class="headline">
            <h2 class="headline__title">Latest <span>Post</span></h2>
            <span class="headline__meta">Some Fact for the Travellers</span>
        </div>
        <div class="row">
            <div class="col xs-12 sm-4" v-for="(blog,index) in blogposts" :key="index">
                <article class="latest__post">
                    <div class="latest__post-img">
                        <router-link :to="'/blog/'+blog.slug"><img :src="urlPath+'uploads/media/blog/thumbnail/440230_'+blog.image" alt="blog.title"></router-link>

                    </div>
                    <div class="latest__post-desc">
                        <time datetime="2011-06-26" class="latest__post-dated">{{formatDate(blog.created_at)}}</time>
                        <h2 class="latest__post-title" data-mh="blogtitle">
                            <router-link :to="'/blog/'+blog.slug">{{blog.title}}</router-link>
                        </h2>
                    </div>
                </article>
            </div>

        </div>
    </div>
</section>
</template>

<script>
import axios from 'axios';
import dayjs from 'dayjs';
export default {
    name: 'Latestpost',
    data() {
        return {
            blogposts: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.showpost();
    },
    methods: {
        showpost() {
            axios.get(this.apiPath+'showfixedblogs/3')
                .then((response) => {
                    this.blogposts = response.data;
                })
        },
        formatDate(dateString) {
            const date = dayjs(dateString);
            return date.format('DD MMM, YYYY');
        },
    }
}
</script>
